import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout pageId={"/about-us"}>
{/*
      <div className="container-fluid">
          <img src="../images/about-us/1.png" className="img-fluid"/>
      </div>
*/}
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col">
          <h1 className="text-center my-5">
            公司简介
          </h1>
          <p>
            北京果心科技有限公司是一家以人工智能、物联网、云计算为核心技术驱动的中关村高新科技企业
            公司由留学归国人员创办，孵化于中科院留学人员创新创业基地和中关村创业大街翼计划，并获得北京市创新创业基金支持和中关村创客小镇投资。
            果心科技专注于智慧园区、智慧社区领域的软硬件产品研发，拥有十余项自主知识产权。公司服务对象包括地产物业公司、企业、政府、学校等，为产业园区、办公空间、住宅社区、
            学校园区等多形态物业项目提供一站式软硬件智慧化解决方案，有效提高企业管理效率、提升园区社区服务品质。
            公司产品由企业管理平台、物业管理平台、数据分析平台、物联分析平台、物联开放平台以及各种智能硬件产品构成，业务模块涵盖通知、报修、巡更、缴费、会议室、服务单、访客、企业管理、数据大屏、
            移动报表、智能安防、租赁、社区运营等各类园区、社区、企业管理需求，服务客户包括碧桂园、花样年集团、运达集团、中国铁建、金蝶、中关村创客小镇等行业领先客户。
          </p>
          <h1 className="text-center my-5">
            合作伙伴
          </h1>
          <div className="row justify-content-center my-5">
            <img src="../images/about-us/2.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-auto" style={{fontWeight: 'bold', fontSize: 25, height: 80}}>
                联系我们
              </div>
            </div>
            <div className="row" style={{height: 50}}>
              <div className="col-auto" style={{fontSize: 18}}>
                <img src="../images/about-us/3_1.png" className="img-fluid"  style={{marginRight: 10}} alt=""/>
                  185-1425-7332
              </div>
            </div>
            <div className="row" style={{height: 50}}>
              <div className="col-auto" style={{fontSize: 18}}>
                <img src="../images/about-us/3_2.png" className="img-fluid"  style={{marginRight: 10}} alt=""/>
                info@guosim.com
              </div>
            </div>
            <div className="row" style={{height: 50}}>
              <div className="col-auto" style={{fontSize: 18}}>
                <img src="../images/about-us/3_3.png" className="img-fluid"  style={{marginRight: 10}} alt=""/>
                北京市海淀区中关村创客小镇15号楼2层
              </div>
            </div>
              <div className="row" style={{height: 200}}>
                  <div>
                      <div className="row" style={{height: 150}}>
                          <div className="col-auto" style={{fontSize: 18}}>
                              <img src="../images/about-us/qrcode.png" className="img-fluid"  style={{marginRight: 10, width: 150, height: 150}} alt=""/>
                          </div>
                      </div>
                      <div className="row" style={{height: 50}}>
                          <div className="col-auto" style={{fontSize: 18,marginLeft: 10,}}>
                              联系微信客服
                          </div>
                      </div>
                  </div>
                  <div>
                      <div className="row" style={{height: 150}}>
                          <div className="col-auto" style={{fontSize: 18}}>
                              <img src="../images/about-us/appQrCode.png" className="img-fluid"  style={{marginRight: 10, width: 150, height: 150}} alt=""/>
                          </div>
                      </div>
                      <div className="row" style={{height: 50}}>
                          <div className="col-auto" style={{fontSize: 18,marginLeft: 10,}}>
                              下载门禁卡APP
                          </div>
                      </div>
                  </div>
              </div>

          </div>
{/*
          <div className="col-12 col-md-6">
            <img src="../images/about-us/3.png" className="img-fluid"/>
          </div>
*/}
        </div>
      </div>
    </div>
  </Layout>
)
